.svg {
  aspect-ratio: 1;
  width: 50vmin;
  height: 50vmin;
}

.clockFace {
  stroke: #333;
  fill: white;
}

.minor {
  stroke: #999;
  stroke-width: 0.5;
}

.major {
  stroke: #333;
  stroke-width: 1;
}

.hour {
  stroke: #333;
}

.minute {
  stroke: #666;
}
