.input::placeholder {
  color: theme(colors.foreground);
  font-style: italic;
  font-weight: 400;
  opacity: 0.4;
}

.newTodo,
.edit {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  margin: 0;
  padding: 6px;
  border: 1px solid #999;
  box-shadow: inset 0 -1px 5px 0 rgba(0 0 0 / 20%);
  color: inherit;
  font-weight: inherit;
  font-size: 24px;
  line-height: 1.4em;
  font-family: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.newTodo {
  height: 65px;
  padding: 16px 16px 16px 60px;
  border: none;
  background: rgba(0 0 0 / 0.3%);
  box-shadow: inset 0 -2px 1px rgba(0 0 0 / 3%);
}
