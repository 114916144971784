.container {
  display: none;
}

.svg {
  transform: rotate(90deg);
  color: theme(colors.background);
}

.arm {
  transform-origin: -130px -106px;
}

@keyframes wave {
  0% {
    transform: rotate(0deg);
  }

  20% {
    transform: rotate(-25deg);
  }

  40% {
    transform: rotate(10deg);
  }

  60% {
    transform: rotate(-25deg);
  }

  80% {
    transform: rotate(10deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@screen lg {
  .container {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
  }

  .svg {
    transform: none;
    clip-path: polygon(100% 100%, 100% 0, 0 0%);
  }

  .arm {
    transform-origin: 130px 106px;
  }

  .svg:hover .arm {
    animation: wave 560ms ease-in-out;
  }
}

@media print {
  .container {
    display: none;
  }
}
