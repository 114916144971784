.footer {
  height: 20px;
  padding: 10px 15px;
  border-top: 1px solid #e6e6e6;
  font-size: 15px;
  text-align: center;
}

.footer::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 50px;
  overflow: hidden;
  box-shadow:
    0 1px 1px rgba(0 0 0 / 20%),
    0 8px 0 -3px #f6f6f6,
    0 9px 1px -3px rgba(0 0 0 / 20%),
    0 16px 0 -6px #f6f6f6,
    0 17px 2px -6px rgba(0 0 0 / 20%);
  content: "";
}

.todoCount {
  float: left;
  text-align: left;
}

.todoCount strong {
  font-weight: 300;
}

.filters {
  position: absolute;
  right: 0;
  left: 0;
  margin: 0;
  padding: 0;
  list-style: none;
}

.filters li {
  display: inline;
}

.filters li a {
  margin: 3px;
  padding: 3px 7px;
  border: 1px solid transparent;
  border-radius: 3px;
  color: inherit;
  text-decoration: none;
}

.filters li a:hover {
  border-color: #db7676;
}

.filters li a.selected {
  border-color: #ce4646;
}

.clearCompleted,
.clearCompleted:active {
  position: relative;
  float: right;
  line-height: 19px;
  text-decoration: none;
  cursor: pointer;
}

.clearCompleted:hover {
  text-decoration: underline;
}

@media (max-width: 430px) {
  .footer {
    height: 50px;
  }

  .filters {
    bottom: 10px;
  }
}
