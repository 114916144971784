.todoItem {
  position: relative;
  border-bottom: 1px solid #ededed;
  font-size: 24px;
}

.todoItem:last-child {
  border-bottom: none;
}

.todoItem.editing {
  padding: 0;
  border-bottom: none;
}

.todoItem.editing .edit {
  display: block;
  width: calc(100% - 43px);
  margin: 0 0 0 43px;
  padding: 12px 16px;
}

.todoItem.editing .view {
  display: none;
}

.todoItem .toggle {
  appearance: none;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 40px;

  /* auto, since non-WebKit browsers doesn't support input styling */
  height: auto;
  margin: auto 0;
  border: none; /* Mobile Safari */
  text-align: center;
  opacity: 0;
}

.todoItem .toggle + label {
  /*
		Firefox requires `#` to be escaped - https://bugzilla.mozilla.org/show_bug.cgi?id=922433
		IE and Edge requires *everything* to be escaped to render, so we do that instead of just the `#` - https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/7157459/
	*/
  background-image: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2240%22%20height%3D%2240%22%20viewBox%3D%22-10%20-18%20100%20135%22%3E%3Ccircle%20cx%3D%2250%22%20cy%3D%2250%22%20r%3D%2250%22%20fill%3D%22none%22%20stroke%3D%22%23949494%22%20stroke-width%3D%223%22/%3E%3C/svg%3E");
  background-position: center left;
  background-repeat: no-repeat;
}

.todoItem .toggle:checked + label {
  background-image: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2240%22%20height%3D%2240%22%20viewBox%3D%22-10%20-18%20100%20135%22%3E%3Ccircle%20cx%3D%2250%22%20cy%3D%2250%22%20r%3D%2250%22%20fill%3D%22none%22%20stroke%3D%22%2359A193%22%20stroke-width%3D%223%22%2F%3E%3Cpath%20fill%3D%22%233EA390%22%20d%3D%22M72%2025L42%2071%2027%2056l-4%204%2020%2020%2034-52z%22%2F%3E%3C%2Fsvg%3E");
}

.todoItem label {
  display: block;
  padding: 15px 15px 15px 60px;

  /* color: #484848; */
  color: theme(colors.foreground);
  font-weight: 400;
  line-height: 1.2;
  overflow-wrap: break-word;
  transition: color 0.4s;
}

.todoItem.completed label {
  color: #949494;
  text-decoration: line-through;
}

.todoItem .destroy {
  display: none;
  position: absolute;
  top: 0;
  right: 10px;
  bottom: 0;
  width: 40px;
  height: 40px;
  margin: auto 0;
  color: #949494;
  font-size: 30px;
  transition: color 0.2s ease-out;
}

.todoItem .destroy:hover,
.todoItem .destroy:focus {
  color: #c18585;
}

.todoItem .destroy::after {
  display: block;
  height: 100%;
  content: "×";
  line-height: 1.1;
}

.todoItem:hover .destroy {
  display: block;
}

.todoItem .edit {
  display: none;
}

.todoItem.editing:last-child {
  margin-bottom: -1px;
}

/*
	Hack to remove background from Mobile Safari.
	Can't use it globally since it destroys checkboxes in Firefox
*/
@media screen and (min-resolution: 0) {
  .todoItem .toggle {
    height: 40px;
    background: none;
  }
}
