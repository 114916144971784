.section {
  width: 100%;
}

.centering {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

@supports (min-height: 100dvh) {
  .section,
  .centering {
    min-height: calc(100dvh - 68px);
  }

  @media screen(lg) {
    .section,
    .centering {
      min-height: 100vh;
    }
  }
}

@media print {
  .section,
  .centering {
    min-height: auto;
  }
}
