@keyframes threedots {
  93.75%,
  100% {
    opacity: 0.2;
  }
}

.dots {
  animation: 0.8s linear 0s infinite threedots;
}

.dotTwo {
  animation-delay: 0.15s;
}

.dotThree {
  animation-delay: 0.3s;
}
