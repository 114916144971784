.hamburger {
  color: theme(colors.white);
}

.hamburger .line {
  transform-origin: center;
  transition:
    y 150ms ease-in 150ms,
    rotate 150ms ease-in,
    opacity 0ms 150ms;
}

.hamburger.open .line {
  transition:
    y 150ms ease-in,
    rotate 150ms ease-in 150ms,
    opacity 0ms 150ms;
}

.hamburger.open :is(.top, .bottom) {
  y: 45;
}

.hamburger.open .top {
  rotate: 45deg;
}

.hamburger.open .middle {
  opacity: 0;
}

.hamburger.open .bottom {
  rotate: -45deg;
}

@media (prefers-reduced-motion) {
  .hamburger .line,
  .hamburger.open .line {
    transition: none;
  }
}
