.container {
  margin-top: theme(margin.6);
}

.city {
  grid-area: city;
  margin-top: theme(margin.px);
  margin-left: theme(margin.2);
}

.company {
  grid-area: company;
  margin-top: theme(margin.4);
  font-size: theme(fontSize.3xl);
}

.dates {
  --tw-text-opacity: 1;
  grid-area: dates;
  margin-top: theme(margin.2);
  margin-left: theme(margin.2);
  font-weight: theme(fontWeight.semibold);
}

.description {
  --tw-text-opacity: 1;
  grid-area: description;
  margin-top: theme(margin.4);
}

@screen lg {
  .container {
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-template-areas:
      "company dates"
      "city dates"
      "description dates";
  }

  .dates {
    margin-left: 0;
  }
}
