.container {
  @apply top-0 z-10 bg-gradient-to-r from-sky-700 via-sky-800 to-sky-900;
  display: grid;
  position: sticky;

  grid-template-areas:
    "profile icon"
    "sections sections";
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: theme(padding.3);
  isolation: isolate;
}

.icon {
  grid-area: icon;
  justify-self: end;
}

.profile {
  grid-area: profile;
  justify-self: start;
}

.sections {
  grid-area: sections;
}

@media screen(md) {
  .container {
    grid-template-areas: "profile sections";
  }

  .icon {
    display: none;
  }
}

@media screen(lg) {
  .container {
    grid-template-areas:
      "profile"
      "sections";
    align-content: center;
    justify-content: center;
    width: theme(width.64);
    height: 100%;
    min-height: 100dvh;
  }

  .icon {
    display: none;
  }
}

@media print {
  .container {
    display: static;
  }

  .icon {
    display: none;
  }
}
